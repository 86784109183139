<template>
  <v-list-item
    link
    target="_blank"
    :href="'https://admin.salescloud.is/staff/' + staff.uuid"
  >
    <v-list-item-avatar>
      <v-badge
        bordered
        bottom
        color="deep-purple accent-4"
        dot
        offset-x="10"
        offset-y="10"
      >
        <v-avatar color="primary">
          <span class="white--text title">{{ initials }}</span>
        </v-avatar>
      </v-badge>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title> {{ team.displayName }} </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn icon>
        <v-icon color="grey lighten-1">
          mdi-information
        </v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
  export default {
    name: 'TeamListItem',
    props: {
      member: {
        type: Object, 
        default: () => {}
      }
    },
    data: () => ({

    }),
    computed: {
      initials: function() {
        const name = this.staff.address.name_line
        let initials = name.match(/\b\w/g) || []
        return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
      }
    }
  }
</script>
