<template>
  <div>
    <div v-if="staffs === null">
      <template v-for="index in 10">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-two-line"
          class="mx-auto"
          :key="index"
        />
      </template>
    </div>
    <div v-if="staffs !== null && staffs.length > 0">
      <v-list>
        <template v-for="(staff, index) in team">
          <TeamListItem
            :key="staff.uuid"
            :staff="staff"
          />
          <v-divider
            v-if="index < team.length - 1"
            :key="index"
            inset
          />
        </template>
      </v-list>
    </div>
    <div
      class="pa-5"
      v-if="team !== null && team.length === 0"
    >
      <h3>{{ $t('empty') }}</h3>
    </div>
  </div>
</template>

<script>

  import TeamListItem from "../components/TeamListItem"

  export default {
    name: 'TeamList',
    props: {
      team: {
        type: Array, 
        default: () => []
      },
      length: {
        type: Number, 
        default: 0
      },
      step: {
        type: Number, 
        default: 0
      }
    },
    components: {
      TeamListItem
    },
    data: () => ({
      startIndex: 0,
      isLoadingMore: false,
    }),
    methods: {
      loadMore: function() {
        this.isLoadingMore = true
        this.length = this.length + this.step
      }
    }
  }
</script>
